<template>
  <p class="childTitle">Bem vindo!</p>
</template>
<script>
export default {
  name: "Profile",
};
</script>
<style lang="scss" scoped>
@import "./GeralChildStyle.scss";
</style>
